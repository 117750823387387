@use "sass:math";
main {
  display: block;
  background: $white;
  margin: 0;
  padding: math.div($gutter, 2) 0;

  min-height: 50vh;

  @include media(tablet) {
    padding: 15px 0;
    min-height: 74vh;
  }
}

.breadcrumbs {
  display: none;
  color: $black;

  @include media(tablet) {
    display: block;
    margin: 0;
    margin-top: 0;
    margin-bottom: -$gutter * 0.67;
    font-size: 14px;

    .breadcrumbs-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .breadcrumbs-link {
      text-decoration: none;
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .breadcrumbs-list-item {
      display: inline-block;
      position: relative;
      margin-left: math.div($gutter, 3);
      padding-left: math.div($gutter, 4);
      margin-bottom: math.div($gutter, 6);

      &:before {
        content: "/";
        color: $black;
        display: block;
        position: absolute;
        top: 3px;
        left: -4px;
        margin: auto 0;
      }

      &:first-child {
        margin-left: 0;
        padding-left: 0;

        &:before {
          display: none;
        }
      }
    }
  }
}
.modal{
  display: block;
  height: 100vh;
  background: rgba(0,0,0, 0.7);
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
  overflow: hidden;
}
.modal-dialog{
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border: 4px solid #000;
  min-width: 400px;
  button{
    margin-left: 0px;
  }
  .heading-large{
    margin-top: 0;
  }
}
.question-four {
  .form-hint{
    margin-bottom: 15px;
  }
  .form-control-1-16{
    width: 51.19px;
  }
  .form-control-1-10{
    width: 85.33px;
  }
  .form-label {
    font-size: 19px!important;
    line-height: 1.31!important;
    margin: 0 0 5px;
  }
  .form-control{
    width: 100%!important;
    height: 40px;
  }
}
#planPathwayDescription{
  .selectOptions{
    button.column-fifth{
      height: 4em!important;
    }
  }
}
.hiddenForAT {
  display: none !important;
}
//New-GDS-Header
.header {
  .header-container{
    background-color: #0b0c0c;
    // border-bottom: 10px solid #1d70b8;
    max-width: 100%;
    height: 50px;
    a.header-link-home{
      .header-logotype {
        img{
          height: 62px;
        }
      }
      &:focus{
        box-shadow: none;
        .header-logotype {
          img{
            filter: brightness(0);
          }
        }
      }
    }
    .rightNav{
      width: auto;
      li{
        margin-top: 11px;
      }
      :nth-child(3){
        display: none;
      }
      a{
        color: #fff;
        font-family: "Helvetica";
        text-decoration: none;
        margin-right: 0;
        font-size: 19px !important;
        &:hover{
          color: #fff !important;
          text-decoration: underline;
        }
        &:focus{
          color: #0b0c0c;
          background-color: #fd0;
          box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
          text-decoration: none;
        }
      }
    }
  }
  .menu{
    border-bottom: 1px solid #b1b4b6;
    background-color: #f8f8f8;
    max-width: 100%;
    .header-navigation-container{
      background: none!important;
      border: none;
      ul{
        height: 33px;
        a{
          font-size: 19px!important;
        }
      }
    }
  }
  .redborder{
    border-bottom: 10px solid #d4351c;
  }
  .blueborder{
    border-bottom: 10px solid #1d70b8;;
  }
}
//New-GDS-Footer
.govuk-footer {
  padding-bottom: 25px;
  padding-top: 40px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
  border-top: 1px solid #b1b4b6;
  color: #0b0c0c;
  background: #f3f2f1;
}
.govuk-width-container {
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 1024px) {
    margin: 0 10px;
  }
}
.govuk-footer__navigation {
  margin-right: -15px;
  margin-left: -15px;
  ::after{
      content: "";
      display: block;
      clear: both;
  }
}
.govuk-footer__section {
  display: inline-block;
  margin-bottom: 30px;
  vertical-align: top;
}
.govuk-grid-column-two-thirds {
  width: 66.6666%;
  float: left;
  box-sizing: border-box;
  padding: 0 15px;
}
.govuk-footer__heading {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #b1b4b6;
}
.app-prose-scope h3, .app-contact-panel__heading, .govuk-heading-m {
  font-size: 24px;
  line-height: 1.25;
  color: #0b0c0c;
  font-weight: 700;
  line-height: 1.1111111111;
  display: block;
  margin-top: 0;
  margin-bottom: 15px;
}
.govuk-footer__list--columns-3 {
  column-count: 3;
}
.govuk-footer__list {
  margin: 0;
  padding: 0;
  list-style: none;
  column-gap: 30px;
}
.govuk-footer__list-item {
  margin-left: 0px !important;
  margin-bottom: 20px;
  :link{
      color: #0b0c0c;
      cursor: pointer;
      text-underline-offset: .1em;
      text-decoration-thickness: 1px;
      margin-right: 0px !important;
      font-size: 16px !important;
      &:hover{
        text-decoration-thickness: 1.5px !important;
        color: rgba(11,12,12,.99) !important;
      }
  }
}
.govuk-grid-column-one-third {
  width: 33.3333%;
  float: left;
  box-sizing: border-box;
  padding: 0 15px;
  min-height: 185px;
}
.govuk-footer__section-break {
  margin-bottom: 30px !important;
  margin: 0;
}
.govuk-footer__meta {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  justify-content: center;
}
.govuk-footer__copyright-logo {
  display: inline-block;
  min-width: 125px;
  padding-top: 112px;
  background-image: url(/assets/images/govuk-crest.png);
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: 125px 102px;
  text-align: center;
  white-space: nowrap;
}
.govuk-footer__meta-item--grow {
  flex: 1;
}
.govuk-footer__licence-logo {
  margin-right: 10px;
}
.together-mode-icon {
  height: 25px;
  width: 35px;
  cursor: pointer;
  margin-right: 10px;
}
//saved content New GDS style
.myContent-card {
  .description {
    height: auto;
  }
}
.saved-container {
  .myContent-card {
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
  }
  a.title {
    font-weight: 400 !important;
    font-family: Helvetica, Arial, sans-serif !important;
  }
}
//Menu button new gds style

.expBtn {
  color: #0b0c0c;
  font-size: 19px;
  padding: 10px;
  text-decoration: none;
  display: block;
  margin-right: 0 !important;
  width: 100%;
  text-align: left;
  &:hover {
    background-color: #b1b4b6;
  }
}

.dropbtn {
  background-color: #f3f2f1;
  color: $black;
  padding: 8px 10px 7px;
  font-size: 19px;
  line-height: 1.106;
  box-shadow: 0 2px 0 #929191;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #dbdad9;
  }
  &:after {
    content: "";
    display: inline-block;
    height: 5px;
    margin-left: 10px;
    vertical-align: middle;
  }
  &:before{
    background-image: url(./../../assets/images/icon-arrow-black-down.svg);
    content: "";
    float: right;
    height: 5px;
    margin: 10px 0;
    width: 10px;
    vertical-align: middle;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 430px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  a {
    color: $black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    margin-right: 0 !important;
  }
}

.dropdown a:hover {background-color: #ddd;}
#myDropdown{
  display: none;
}
#myDropdownMain.show{
  .dropbtn {
    &::before{
      background-image: url(./../../assets/images/icon-arrow-black-up.svg);
      content: "";
      height: 5px;
      margin: 10px 0;
      width: 10px;
      vertical-align: middle;
      float: right;
    }
  }
  .dropbtn {
    background-image: none;
  }
  #myDropdown{
    display: block;  
  }
}

//cource start 

.cource-card {
  padding-bottom: 15px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  .description {
    height: auto !important;
  }
  p {
    margin: 0 !important;
  }
  .title {
    font-family: "helvetica" !important;
    margin-bottom: 0px;
  }
  a.title {
    font-weight: 400;
    margin-bottom: 0px !important;
  }
  a {
    &:hover {
      background: none !important;
    }
    &:active {
      box-shadow: none !important;
    }
  }
}
.result-card-descr {
  height: auto !important;
}
.result-container {
  .result-card {
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
  }
}
a.courseTitle.link {
  font-weight: 400 !important;
  font-family: Helvetica, Arial, sans-serif !important;
}
.result-small-title {
  border-bottom: 1px solid #ccc;
}
.heading-results {
  margin-bottom: 40px;
}
.heart-favourite-container {
  // float: right !important;
  margin-top: 5px;
}
.health-search{
  width: 70%!important;
}
//main search

.courses-search-section{
  overflow: hidden;
  padding: 40px 0 60px;
  background: #e0eef8;
  margin-bottom: 40px;
  .nomargin {
    margin: 0 ;
  }
  .heading-large {
    font-size: 24px;
    margin-bottom: 5px;
  }
  .search-field{
    margin-top: 20px;
  }
  .ml40 {
    margin-left: 40px;
  }
  .form-control {
    height: 27px;
    padding: 5px;
  }
  button#id-submit-button {
    margin-left: 10px;
  }
}
.heart-favourite-container {
  // float: right !important;
  margin-top: 5px;
}
//Serach result new GDS style
.search-results {
  display: flex;
  flex-direction: column;
  a {
    &:hover {
      background: none !important;
    }
    &:active {
      box-shadow: none !important;
    }
  }
}
/*history timeline*/
.moj-timeline{
  // overflow: hidden;
  position: relative;
  .moj-timeline__item{
    padding-bottom: 40px;
    padding-left: 20px;
    position: relative;
      &::before{
        background-color: #1d70b8;
        content: "";
        height: 5px;
        left: 0;
        position: absolute;
        top: 10px;
        width: 15px;
      }
    .moj-timeline__header{
      .moj-timeline__title{
        font-size: 19px;
        line-height: 1.3157894737;
        font-family: 'helvetica_bold';
      }
    }
    .moj-timeline__date{
      font-size: 16px;
      line-height: 1.25;
      font-family: 'helvetica';
      font-weight: 400;
    }
    .moj-timeline__description{
      ul{
        li{
          display: list-item;
          margin-left: 25px;
          position: relative;
          a{
            position: absolute;
            top: 12px;
          }
          &::marker{
            font-size: 30px;
            color: #333;
          }
        }        
      }
    }
  }
  &::before{
    background-color: #1d70b8;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 10px;
    width: 5px;
  }
}
.entry-title {
  font-family: "helvetica_bold";
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.entry-title {
  font-size: 19px !important;
}
.entry-subtitle {
  font-size: 16px !important;
}
.entry-system-content {
  font-family: "helvetica" !important;
}
.entry-pathway-container .entry-subtitle {
  font-size: 19px !important;
}
.change-pass-screen{
  //overflow: hidden;
  .sign-out-align{
    display: block!important;
  }
  .change-inner-form{
    max-height: 400px;
    overflow: auto;
    margin-bottom: 10px;
  }
}
@media all and (max-width: 835px) {
  .navigation-item {
    padding: 0 10px !important;
  }
}